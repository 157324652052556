.Background {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
    background-image: url('BG.png');
    background-repeat: no-repeat;
    background-size: 100%;
}

.Name {
    position: absolute;
    margin-top: 7%;
    margin-left: 50%;
    margin-right: 50%;
}

.DownArrow {
    margin: 75px auto 0px auto;
}