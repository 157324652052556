.Background {
    background-image: linear-gradient(#33DEB2, #03A5AA);
    height: 100vh;
    min-height: 836px;
    z-index: 0;
    display: flex;
    overflow: hidden;
}

.Graphic {
    margin-right: 5%;
    margin-left: auto;
    min-width: 788px;
    min-height: 836px;
    overflow: hidden;
    transform: scale(1);
}

.SkillsBox {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    width: 440px;
    background-color: white;
    background: rgba(255, 255, 255, 0.2);
    padding: 8px 5px 8px 5px;
}

.SkillsBox:hover {
    animation: example .5;
}

@keyframes example {
    from {transform: translate(0);}
    to {transform: translate(30px);}
  }

.SkillsContainer {
    height: 350px;
    width: 500px;
    margin: 0;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    font-size: 30px;
}

.Content {
    margin-top: 2%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

.visible {
    display: none;
}