.Background {
    height: 100vh;
    background-color: #003146;
    overflow: hidden;
    position: relative;
}

.Content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2fr 1fr 4fr 4fr;
}

.LinkContainer {
    grid-row: 3;
    grid-column: 1 / span 4;
    justify-content: space-around;
}

@media (max-width: 1200px) {
    .Background {
        min-height: 1500px;
    }
    .Content {
        margin-left: auto;
        margin-right: auto;
        justify-content: space-around;
        display: flex;
        flex-direction: column;
    }
}

.Link {
    cursor: pointer;
    grid-row: 3;
    justify-self: center;
    align-self: center;
    position: relative;
    transform: scale(1.0);
    transition-property: transform;
    transition-duration: 1s;
}

.Link:hover {
    transform: scale(1.1);
    transition-property: transform;
    transition-duration: 1s;
}

.Link:hover .overlay {
    animation: here 1s forwards;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: black;
    z-index: 1;
    border-radius: 10px;
    color: white;
  }

.One {
    @extend .Link;
    grid-column: 1;
}

.Two {
    @extend .Link;
    grid-column: 2;
}

.Three {
    @extend .Link;
    grid-column: 3;
}

@keyframes here {
    from { 
        background-color: transparent;
        opacity: 0;
    }
    to { 
        background-color: black;
        opacity: .7;
    }
}

.Title {
    margin: 0;
    align-self: end;
    padding-left: 13%;
    grid-row: 2;
    grid-column: 1;
    letter-spacing: .15em;
    color: white;
}

.FooterWrapper {
    grid-row: 4;
    grid-column: 1 / span 4;
    align-self: end;
}

.Image {
    display: block;
    object-fit: cover;
    overflow: hidden;
    border: aqua 2px solid;
    border-radius: 10px;
    width: 400px;
    height: 250px;
    transform: scale(1);
    transition-property: transform;
    transition-duration: .5s;
}



// .Image:hover .overlay {
//     display: block;
// }

.Image:hover {
    transform: scale(1.1);
    transition-property: transform;
    transition-duration: .5s;
    opacity: .3;
}
