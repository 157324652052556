.ViewerBox {
    background-color: white;
    height: inherit;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition-property: transform;
    transition-duration: .5s;
}

.Viewer {
    overflow: hidden;
    height: 300px;
    width: 450px;
    border-radius: 7px;
    margin: 0;
}

.ViewerContainer {
    width: 450px;
    height: 350px;
}

.Slide {
    width: 450px;
    float: left;
}

.Arrow {
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: auto;
    width: 10px;
    height: 10px;
    border: solid rgba(0, 0, 0, 0.18);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.ViewerNavigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Indicators {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 300px;
    align-items: center;
}

.Indicators div {
    cursor: pointer;
    height: 3px;
    width: 70px;
    background-color: white;
    opacity: .4;
    border-radius: 5px;
}

.Header {
    color: white;
    padding-left: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.Text {
    color: white;
    padding-left: 50px;
    padding-right: 15px;
    list-style-type: circle;
}

.Item {
    list-style-type: circle;
}

.RightArrow {
    cursor: pointer;
    transform: rotate(-90deg) scale(1);
    margin-right: 20px;
    float: right;
    transition-property: transform;
    transition-duration: .5s;
}

.RightArrow:hover {
    transform: rotate(-90deg) scale(1.3);
    transition-property: transform;
    transition-duration: .5s;
}

.LeftArrow:hover {
    transform: rotate(90deg) scale(1.3);
    transition-property: transform;
    transition-duration: .5s;
}

.LeftArrow {
    cursor: pointer;
    margin-left: 20px;
    transform: rotate(90deg) scale(1);
    transition-property: transform;
    transition-duration: .5s;
}