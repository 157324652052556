.Footer {
    height: 300px;
    min-height: 200px;
    background-color: #001C28;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media (max-width: 1200px) {
    .Footer {
        width: 100vw;
        height: 600px;
        justify-content: space-around;
        display: flex;
        flex-direction: column;
    }
}

.CenterContainer {
    display: flex;
    flex-direction: column;
}

.Profile {
    border-radius: 50%;
    transform: scale(.8);
    margin: auto;
}

.Resume {
    align-self: center;
    margin: auto;
    transform: scale(1);
    transition-property: transform;
    transition-duration: .5s;
}

.Resume:hover {
    transform: scale(1.05);
    transition-property: transform;
    transition-duration: .5s;
}

.Name {
    transform: scale(.8);
}

.EmailNumber {
    margin: auto;
    font-family: proxima-nova, sans-serif;
    color: rgb(51,222,178);
    letter-spacing: .05em;
}